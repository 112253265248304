








































































































import Vue from 'vue'
import Component from 'vue-class-component'
import InfoBox from '@/components/hszg/InfoBox.vue'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { ApplicationModel } from '@/store/inquiry/types'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { InfoBox, BaseForm }
})
/*
@group FORMS
 */
export default class InquiryModal extends Vue {
  // unique identifier
  @Prop({ required: true })
  id: string

  // the modal title
  @Prop({ required: true })
  title: string

  // an optional help text in front of the form in the modal
  @Prop({ required: false })
  helpText: string

  // set true if it should be an application for a job ad instead of a normal inquiry
  @Prop({ required: false, default: false })
  isApplication: boolean

  // if true a warning is shown
  @Prop()
  showWarning: boolean

  // the warning to be shown
  @Prop({ required: true })
  warning: string

  // label for the text area of the inquiry
  @Prop({ required: true })
  textAreaLabel: string

  // used when emitting the inquiry - only text or the whole ApplicationModelvues
  @Prop({ required: false })
  onlyText: boolean

  messageState = true

  get fields (): Form[] {
    return [
      {
        id: 'text',
        key: 'text',
        type: InputTypes.TEXTAREA,
        label: this.textAreaLabel,
        required: this.isApplication,
        md: 12,
        state: this.messageState
      }
    ]
  }

  get layouts (): any[] {
    return Object.keys(this.$t('student.CV_LAYOUTS')).map(key => {
      return {
        id: key,
        title: this.$t('student.CV_LAYOUTS.' + key),
        src: require(`@/assets/CV/${key}.png`)
      }
    })
  }

  private inquiryModel: ApplicationModel = {
    text: '',
    unlockPdfDownload: false,
    cvLayout: ''
  }

  sendInquiry (): void {
    this.messageState = !this.isApplication || this.inquiryModel.text !== ''
    if (this.messageState) {
      if (this.onlyText) {
        this.$emit('input', this.inquiryModel.text)
      } else {
        this.$emit('input', this.inquiryModel)
      }
    }
  }

  mounted (): void {
    this.inquiryModel.cvLayout = this.layouts[0].id
  }
}
