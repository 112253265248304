











































































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import TargetCompetenceProfileResult from '@/components/kode/TargetCompetenceProfileResult.vue'
import { CompetenceDetail, JobFinderType } from '@/store/kode/types'
import { Action, Getter, State } from 'vuex-class'
import { GET_KODE_BRIDGE_RESULT } from '@/store/kode/actions'
import { GenericRequest, SimpleServerResponse, User } from '@/store/types'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import { hasRight } from '@/utils/rights'
import Enums from '@/common/Enums'
import Rights from '@/common/rights'
import { INQUIRE_COMPANY, INQUIRE_COMPANY_CANCEL } from '@/store/student/actions'
import { ApplicationModel, InquiryDto } from '@/store/inquiry/types'
import { ACCEPT_DECLINE } from '@/store/inquiry/actions'
import {
  ACCEPT,
  DECLINE,
  getAcceptDeclinePositiveFeedback,
  getCompanyInquiryConfirmationExplanationForStudent,
  getCompanyInquiryConfirmationHeadingForStudent
} from '@/common/inquiry'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import InquiryModal from '@/components/forms/InquiryModal.vue'
@Component({
  components: { ErrorAlert, TargetCompetenceProfileResult, ConfirmationModal, InquiryModal }
})
/*
@group HSZG
For showing one matching job family as used on job finder page and the matching jobs to own job family page.
*/
export default class JobFamily extends Vue {
  @State('showDesktopVersion') showDesktopVersion: boolean

  // unique identifier
  @Prop({ required: true })
  id: string

  // the job family
  @Prop({ required: true })
  family: JobFinderType

  // if the user can apply to jobs of this family
  @Prop({ required: false })
  apply: boolean

  // the calculation basis for displaying the competence corridors. Possible values:
  // `'average', 'favorable', 'unfavorable', 'competence', 'qualification'`
  @Prop({ required: false, default: 'average' })
  calculationBasis: string

  // Which result type should be displayed (used differently on different pages). Valid types:
  // `'JOB_FAMILY'`, `'CROSS_COMPETENCES'`, `'JOB_AD'`, `'JOB_AD_NO_COMP'`
  @Prop({
    required: false,
    default: 'JOB_FAMILY',
    validator (value: string): boolean {
      return ['JOB_FAMILY', 'CROSS_COMPETENCES', 'JOB_AD', 'JOB_AD_NO_COMP'].indexOf(value) !== -1
    }
  })
  targetProfileType: string

  @Action(GET_KODE_BRIDGE_RESULT)
  public getKodeBridgeResult: (id: string) => Promise<SimpleServerResponse>

  @Action(INQUIRE_COMPANY)
  inquireCompany: (requestDto: InquiryDto) => Promise<InquiryDto>

  @Action(ACCEPT_DECLINE)
  public acceptOrDecline: (dto: GenericRequest) => Promise<SimpleServerResponse>

  @Action(INQUIRE_COMPANY_CANCEL)
  inquireCompanyCancel: (requestDto: GenericRequest) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  @Watch('calculationBasis')
  onValueChange (): void {
    this.showDetails = false
  }

  loading = false
  error = {}
  requestFailed = false
  showInquiryWarning = false
  showDetails = false
  kodeBridgeResult: Array<CompetenceDetail> = []
  errorMessage = ''
  modalHeading = ''
  modalExplanation = ''
  desiredAction = ''
  respondent: string | null | undefined
  inquiryButtons: string[] = []

  get DECLINE (): string {
    return DECLINE
  }

  get ACCEPT (): string {
    return ACCEPT
  }

  get hasRightToApply (): boolean {
    return hasRight(Rights.INQUIRE_COMPANY)
  }

  get hasRightToReactToInquiry (): boolean {
    return hasRight(Rights.INQUIRY_ANSWER)
  }

  get inquiryGetter () {
    return Enums
  }

  inquiryResponse (): void {
    let acceptInquiry = 'false'
    if (this.desiredAction === ACCEPT) {
      acceptInquiry = 'true'
    }
    let inquiryId = ''
    if (this.family.inquiryId !== undefined && this.family.inquiryId !== null) {
      inquiryId = this.family.inquiryId
    }
    this.acceptOrDecline({ query: [acceptInquiry, inquiryId] }).then(() => {
      this.family.inquiryStatus = this.inquiryGetter.INQUIRY_DENIED_BY_COMPANY
      if (acceptInquiry === 'true') {
        this.family.inquiryStatus = this.inquiryGetter.INQUIRY_ACCEPTED_BY_COMPANY
      }
      this.family.inquiryId = inquiryId
      const message = getAcceptDeclinePositiveFeedback(this.desiredAction)
      this.inquiryButtons = this.desiredAction === ACCEPT ? [DECLINE] : [ACCEPT]
      this.$root.$emit('alert', message, '', false)
    }).catch(() => {
      this.$root.$emit('alert', this.$i18n.t('warning.general-error-message').toString(), '', true)
    })
  }

  applyToJob (): void {
    this.respondent = this.family.companyId
    this.showInquiryWarning = false
    this.$bvModal.show('company-inquiry-' + this.id + '-modal')
  }

  openConfirmationModal (action: string): void {
    this.modalExplanation = getCompanyInquiryConfirmationExplanationForStudent(action)
    this.modalHeading = getCompanyInquiryConfirmationHeadingForStudent(action)
    this.desiredAction = action
    this.$bvModal.show('confirmation-modal-inquiry-' + this.id)
  }

  sendCompanyInquiry (model: ApplicationModel): void {
    if (model.text && model.text.trim().length > 0) {
      const inquiryModel: InquiryDto = {
        companyId: this.respondent as string,
        inquiryText: model.text,
        cvLayout: model.unlockPdfDownload ? model.cvLayout : undefined,
        jobAdId: this.family.targetCompetenceProfile as string,
        talentId: this.userGetter.appUserId
      }
      this.inquireCompany(inquiryModel).then(data => {
        this.$bvModal.hide('company-inquiry-' + this.id + '-modal')
        if (this.family.inquiryStatus !== undefined || this.family.inquiryId !== undefined) {
          this.family.inquiryStatus = data.inquiryStatus
          this.family.inquiryId = data.inquiryId
          this.$root.$emit('alert', this.$i18n.t('job-ad.matching.inquiry-successful').toString(), '')
        }
        this.respondent = undefined
      }).catch(error => {
        this.errorMessage = error.message === 'inquiry already existent' ? this.$t('talent-inquiry.inquiry-already-existent').toString() : this.$t('warning.general-error-message').toString()
        this.showInquiryWarning = true
      })
    } else {
      this.showInquiryWarning = true
    }
  }

  openCancellationConfirmModal (): void {
    this.$bvModal.show('confirmation-modal-cancel-inquiry-' + this.id)
  }

  cancelInquiry (): void {
    if (this.family.inquiryStatus !== undefined || this.family.inquiryId !== undefined) {
      const requestDto: GenericRequest = {
        query: [this.family.inquiryId as string],
        params: undefined
      }
      this.inquireCompanyCancel(requestDto).then(() => {
        if (this.family.inquiryStatus !== undefined || this.family.inquiryId !== undefined) {
          this.family.inquiryStatus = null
          this.family.inquiryId = null
          this.$root.$emit('alert', this.$i18n.t('job-ad.matching.inquiry-canceled').toString(), '')
        }
      }).catch(error => {
        this.errorMessage = error
      })
    }
  }

  get accuracy (): number {
    switch (this.calculationBasis) {
      case 'average': return this.family.average * 100
      case 'favorable': return this.family.favorable ? this.family.favorable * 100 : 0
      case 'unfavorable': return this.family.unfavorable ? this.family.unfavorable * 100 : 0
      case 'competence': return this.family.competenceMatchingResult ? this.family.competenceMatchingResult * 100 : 0
      case 'qualification': return this.family.qualificationMatchingResult ? this.family.qualificationMatchingResult * 100 : 0
      default: return this.family.average
    }
  }

  get roundedAccuracy (): number {
    return Math.round(this.accuracy)
  }

  get detailButtonText (): string {
    if (this.targetProfileType === 'JOB_AD' || this.targetProfileType === 'JOB_AD_NO_COMP') return this.$i18n.t('job-ad.detail-page.show').toString()
    else if (this.showDetails) return this.$i18n.t('job-finder.less-details').toString()
    else return this.$i18n.t('job-finder.more-details').toString()
  }

  selectJobFamily (): void {
    this.showDetails = false
    this.$root.$emit('dismiss-alert')
    this.$emit('choose', this.family.targetCompetenceProfile)
  }

  detailsButtonClicked (id: string): void {
    switch (this.targetProfileType) {
      case 'JOB_FAMILY': {
        this.loadBridgeResult()
        break
      }
      case 'CROSS_COMPETENCES': {
        this.loadBridgeResult()
        break
      }
      case 'JOB_AD': {
        this.gotoDetailsPage(id)
        break
      }
      case 'JOB_AD_NO_COMP': {
        this.gotoDetailsPage(id)
        break
      }
      default: this.loadBridgeResult()
    }
  }

  loadBridgeResult (): void {
    this.$root.$emit('dismiss-alert')
    // if button was pressed and details are collapsed -> load details and display then
    if (!this.showDetails) {
      this.$root.$emit('bv-toggle-collapse', 'job-family-details-collapse_' + this.id)
      this.loading = true
      this.requestFailed = false
      this.getKodeBridgeResult(this.family.targetCompetenceProfile).then((data: SimpleServerResponse) => {
        this.kodeBridgeResult = JSON.parse(data.content.result).competenceDetails
      }).catch(error => {
        this.error = error
        this.requestFailed = true
      }).finally(() => {
        this.loading = false
        this.showDetails = true
      })
    } else {
      // otherwise just close the details panel
      this.showDetails = false
    }
  }

  gotoDetailsPage (id: string): void {
    this.$router.push({
      name: 'job-ad',
      params: {
        id: id,
        isApplication: hasRight(INQUIRE_COMPANY).toString(),
        inquiryId: '',
        options: JSON.stringify([])
      }
    })
  }

  mounted (): void {
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (collapseId === 'job-family-details-collapse_' + this.id) {
        this.showDetails = isJustShown
      }
    })
  }
}
