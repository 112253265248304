

































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
/*
A special error alert component which is used for a KODE brigde error in the job family
@group OTHER
 */
export default class ErrorAlert extends Vue {
  // true to show the alert
  @Prop({ required: true })
  show: boolean

  // the error text
  @Prop({ required: false })
  error: Error

  // the h4 heading for the error
  @Prop({ required: false, default: 'Error' })
  heading: string
}
